@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/GothamBook.ttf") format("ttf"),
}

@font-face {
  font-family: "Gotham Bold";
  src: url("./assets/fonts/GothamBold.ttf") format("ttf"),
}

@font-face {
  font-family: "Gotham Medium";
  src: url("./assets/fonts/GothamMedium_1.ttf") format("ttf"),
}

html {
  background-color: #101010;
}

.App {
  background-color: #101010;
  text-align: center;
  color: #ffffff;
}
