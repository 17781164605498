@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);
.numberListWrapper {
  background-color: #fff;
  border: solid 2px #fff;
  display: grid;
  grid-gap: 2px 2px;
  gap: 2px 2px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  margin: 20px;
}

.numberItemStyles {
  align-items: center;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  justify-content: center;
  padding: 20px;
}

.numberItemStyles span {
  font-weight: 500;
  text-transform: uppercase;
}

.numberItemStyles > * {
  margin: 10px 0;
}

.availableTag {
  background-color: #fff;
  color: #000;
  width: 170px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 0;
}

.reservedTag {
  background-color: #000;
  cursor: not-allowed;
  color: #fff;
  width: 170px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 0;
}

.formModalContentWrapper {
  max-width: 30rem;
  height: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chakra-modal__content {
  background-image: url(/static/media/bg_modal.89ef4421.svg) !important;
  background-color: unset !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  max-width: 58rem !important;
  min-height: 45rem;
  padding: 90px 100px;
  display: flex !important;
  align-items: center;
}

.chakra-modal__close-btn {
  background-image: url(/static/media/botao_fechar.ff2c267f.svg) !important;
  background-repeat: no-repeat !important;
  height: 70px !important;
  width: 70px !important;
  top: -3rem !important;
  right: 1.5rem !important;
}

.chakra-modal__close-btn .chakra-icon {
  display: none;
}

.chakra-modal__content-container .chakra-modal__header {
  font-size: 1.5rem !important;
  font-weight: 900 !important;
  text-transform: uppercase !important;
}

.formModalInputGroup > * {
  margin: 20px;
}

.formModalInput:focus {
  border-color: #000 !important;
  box-shadow: 0px 1px 0px 0px #000 !important;
}

.chakra-modal__content-container .chakra-button {
  background-image: url(/static/media/fundo_botao_preto.fc4f8587.svg) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: #ffffff;
  display: flex;
  font-size: 1.5rem !important;
  font-weight: bolder !important;
  height: 10rem !important;
  width: 30rem !important;
}

@font-face {
  font-family: "Gotham";
  src: url(/static/media/GothamBook.58fb9151.ttf) format("ttf"),
}

@font-face {
  font-family: "Gotham Bold";
  src: url(/static/media/GothamBold.108854bb.ttf) format("ttf"),
}

@font-face {
  font-family: "Gotham Medium";
  src: url(/static/media/GothamMedium_1.0a910c0b.ttf) format("ttf"),
}

html {
  background-color: #101010;
}

.App {
  background-color: #101010;
  text-align: center;
  color: #ffffff;
}

